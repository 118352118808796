import { setOptions, dateFormat } from 'highcharts';

// Convert a number 'n' to a human-readable format with abbreviated units (K, M, B)
export const toHumanNumber = function(n) {
  if (n >= 1000000000) {
    return (n / 1000000000).toFixed(1).replace(/\.0$/, '') + 'B';
  }
  if (n >= 1000000) {
    return (n / 1000000).toFixed(1).replace(/\.0$/, '') + 'M';
  }
  if (n >= 1000) {
    return (n / 1000).toFixed(1).replace(/\.0$/, '') + 'K';
  }
  return n;
};

setOptions({
  // Tussell green
  colors: ['#0e9c1b'],
  chart: { backgroundColor: 'none' },
  lang: { numericSymbols: ['K', 'M', 'B', 'T', null, null] },
  tooltip: {
    backgroundColor: '#000000',
    borderColor: '#000000',
    formatter: function() {
      const date = dateFormat('%Y', new Date(this.x));
      return '<p style="color:white">' + date + ': £' + toHumanNumber(this.y) + '</p>';
    }
  },
  // Removes highcharts.com credit link
  credits: { enabled: false }
});
