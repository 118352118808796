import { Controller } from '@hotwired/stimulus';

export default class RevealContentController extends Controller<HTMLFormElement> {
  static targets = ['button', 'content'];
  declare buttonTargets: HTMLElement[];
  declare contentTargets: HTMLElement[];

  toggle(): void {
    this.buttonTargets.forEach((button: HTMLElement) => {
      button.classList.toggle('hidden');
    });
    this.contentTargets.forEach((target: HTMLElement) => {
      target.classList.toggle('hidden');
    });
  }
}
